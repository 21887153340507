import React from "react";
import { Affix, Tooltip } from "antd";
import { BsWhatsapp } from "react-icons/bs";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

const AffixButton = () => {
  const { userProfile } = useSelector((state) => state?.auth);
  const isUserSignedIn = Object.keys(userProfile ?? {}).length > 0;

  const tooltipContent = isUserSignedIn
    ? null
    : (
      <span style={{ fontSize: "12px" }}>
        Free <Link to="/register">signup </Link>
        via Google or Outlook is required for WhatsApp support.
        <br /> This helps filter out spam messages.
      </span>
    );

  const whatsappLink = isUserSignedIn
    ? "https://wa.me/+971504614868"
    : "/whatsapp-support";

  return (
    <div style={{ position: "fixed", bottom: 20, right: 20 }}>
      <Affix offsetBottom={20}>
        <Tooltip title={tooltipContent} placement="left">
          <Link
            to={whatsappLink}
            target={isUserSignedIn ? "_blank" : "_self"}
            rel="noopener noreferrer nofollow"
            title="Contact us via WhatsApp"
            aria-label="Contact us via WhatsApp"
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              width: "50px",
              height: "50px",
              backgroundColor: "rgba(37, 211, 102, 1)", 
              borderRadius: "50%",
              cursor: "pointer",
              boxShadow: "0 2px 8px rgba(0, 0, 0, 0.2)",
            }}
          >
            <BsWhatsapp
              style={{
                width: "30px",
                height: "30px",
                color: "#fff",
              }}
              alt="WhatsApp"
            />
          </Link>
        </Tooltip>
      </Affix>
    </div>
  );
};

export default AffixButton;
