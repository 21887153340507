import { Dropdown } from "antd";
import React from "react";
import { Container, Nav, Navbar } from "react-bootstrap";
import { Link, NavLink } from "react-router-dom";
import { HeaderContent } from "./index.styled";
import { useSelector, useDispatch } from "react-redux";
import { logout } from "../../redux/authSlice";
import { useNavigate } from "react-router-dom";
import CustomImage from "../images";

const Header = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { userProfile } = useSelector((state) => state?.auth);
  const items = [
    {
      label: (
        <Link
          to={`${userProfile?.admin ? "/admin/dashboard" : "/user/dashboard"}`}
        >
          {userProfile?.admin ? "Admin Dashboard" : "Student Dashboard"}
        </Link>
      ),
      key: "0",
    },

    userProfile?.lecturer && {
      label: <Link to={`/lecturer/dashboard`}>Lecturer Dashboard</Link>,
      key: "1",
    },
    {
      label: <Link to="/profile">Setting</Link>,
      key: "2",
    },
    {
      label: <p onClick={() => dispatch(logout({ navigate }))}>Logout</p>,
      key: "3",
    },
  ];

  return (
    <HeaderContent>
      <Navbar bg="light" expand="lg">
        <Container fluid>
          <Navbar.Brand>
            <Link to="/" aria-label="Homepage">
              <CustomImage
                src="/images/logo.svg"
                alt="Company Logo"
                width="90px"
                height="90px"
              />
            </Link>
          </Navbar.Brand>

          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="ms-auto">
              <NavLink to="/" className="nav-link">
                Home
              </NavLink>
              <NavLink
                to="/courses/sql_database_programming_tutorial_courses"
                className="nav-link"
              >
                Courses
              </NavLink>
              <NavLink
                to="/learn_sql_power_bi_analytics_online_training_cost"
                className="nav-link"
              >
                Subscriptions
              </NavLink>
              <NavLink
                to="/beginners_online_sql_training_course_learn_sql_certification"
                className="nav-link"
              >
                Preview
              </NavLink>
              <NavLink to="/sql_database_projects" className="nav-link">
                SQL Projects
              </NavLink>
              <NavLink
                to="/learn_sql_class_online_sql_training_course"
                className="nav-link"
              >
                Live Zoom Class
              </NavLink>
              {Object.keys(userProfile ?? {})?.length > 0 ? (
                <Dropdown
                  menu={{
                    items,
                  }}
                  trigger={["click"]}
                >
                  <Link onClick={(e) => e.preventDefault()}>
                    <NavLink
                      to="#"
                      onClick={(e) => e.preventDefault()}
                      className="profile"
                    >
                      <CustomImage
                        src="/images/user-icon.png"
                        alt="user-icon"
                        width="28px"
                      />
                      <strong>{userProfile?.name?.split(" ")?.[0]}</strong>
                      <span>
                        <CustomImage
                          src="/icons/polygon-icon.svg"
                          alt="polygon-icon"
                        />
                      </span>
                    </NavLink>
                  </Link>
                </Dropdown>
              ) : (
                <>
                  <NavLink to="/login" className="nav-link">
                    Sign in
                  </NavLink>
                  <NavLink to="/register" className="signupBtn">
                    Sign up
                  </NavLink>
                </>
              )}
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </HeaderContent>
  );
};

export default Header;
