import { Col, Row, Tooltip } from "antd";
import React from "react";
import { Container } from "react-bootstrap";
import { Link } from "react-router-dom";
import { CustomContainer } from "./index.style";
// import { IoLogoWhatsapp } from "react-icons/io";
import { BsWhatsapp } from "react-icons/bs";
import { useSelector } from "react-redux";
import CustomImage from "../images";
import { SiSlack } from "react-icons/si";

const Footer = () => {
  const { userProfile } = useSelector((state) => state?.auth);
  return (
    <CustomContainer>
      <Container fluid>
        <Row align="middle">
          <Col xs={{ span: 24 }} md={{ span: 6 }}>
            <div className="footerLogo">
              <CustomImage
                src="/images/footer-logo.svg"
                alt="footer-logo"
                width="60px"
                height="40px"
              />
            </div>
          </Col>
          <Col xs={{ span: 24 }} md={{ span: 14 }}>
            <Row gutter={30}>
              <Col span={8}>
                <div className="footerLinks">
                  <Link to="/courses/sql_database_programming_tutorial_courses">
                    Courses
                  </Link>
                  <Link to="/learn_sql_power_bi_analytics_online_training_cost">
                    Subscriptions
                  </Link>
                  <Link to="/sql_power_bi_software_it_job_internship">
                    Internship
                  </Link>
                  {/* <Link to="/learn_sql_class_online_sql_training_course">
                    Live Zoom Class
                  </Link> */}
                  <Link to="/sql_database_projects">SQL Database Projects</Link>
                  <Link to="/online_sql_query_editor_execution">
                    Online SQL query Editor/Tool
                  </Link>{" "}
                  <Link to="/mssql/database_quiz_questions">
                    MSSQL Quiz Questions
                  </Link>
                  <Link to={`/oracle/database_interview_questions`}>
                    Oracle Interview Questions
                  </Link>
                </div>
              </Col>
              <Col span={8}>
                <div className="footerLinks">
                  <Link to="/about">About Us</Link>
                  <Link to="/terms&conditions">Terms & Conditions</Link>
                  <Link to="/privacypolicy">Privacy Policy</Link>
                  <Link to="/refund-policy">Refund Policy</Link>
                  <Link to="/mysql/database_quiz_questions">
                    MySQL Quiz Questions
                  </Link>

                  <Link to="/postgresql/database_quiz_questions">
                    PostgreSQL Quiz Questions
                  </Link>
                  <Link to={`/mssql/database_interview_questions`}>
                    MSSQL Interview Questions
                  </Link>
                </div>
              </Col>
              <Col span={8}>
                <div className="footerLinks">
                  <Link to="/sitemap">Sitemap</Link>
                  <Link to="/contact">Contact Us</Link>
                  <Link to="/testimonials">Testimonials</Link>

                  {/* <Link to="/forum-threads">Forum</Link> */}
                  <Link to="/beginners_online_sql_training_course_learn_sql_certification">
                    SQL Training Intro
                  </Link>
                  <Link to="/oracle/database_quiz_questions">
                    Oracle Quiz Questions
                  </Link>
                  <Link to={`/mysql/database_interview_questions`}>
                    MySQL Interview Questions
                  </Link>
                  <Link to={`/postgresql/database_interview_questions`}>
                    PostgreSQL Interview Questions
                  </Link>
                </div>
              </Col>
            </Row>
          </Col>
          <Col xs={{ span: 24 }} md={{ span: 4 }}>
            <div className="socialLinks">
              <center>
                <h5>Follow Us</h5>
              </center>
              <div>
                <Link
                  to="https://www.facebook.com/profile.php?id=61559424652248"
                  target="_blank"
                  rel="noopener noreferrer nofollow"
                  title="Visit our Facebook Profile"
                  aria-label="Visit our Facebook Profile"
                >
                  <CustomImage
                    src="/icons/f-icon.svg"
                    alt="Facebook"
                    width="40px"
                    height="40px"
                    className="footerLinks"
                  />
                </Link>
                <Link
                  to="https://www.linkedin.com/in/data-champion/"
                  target="_blank"
                  rel="noopener noreferrer nofollow"
                  title="Visit our LinkedIn Profile"
                  aria-label="Visit our LinkedIn Profile"
                >
                  <CustomImage
                    src="/icons/linkedin-icon.svg"
                    alt="LinkedIn"
                    width="40px"
                    height="40px"
                    className="footerLinks"
                  />
                </Link>
                <Link
                  to="https://twitter.com/Tansyacademy"
                  target="_blank"
                  rel="noopener noreferrer nofollow"
                  title="Visit our Twitter Profile"
                  aria-label="Visit our Twitter Profile"
                >
                  <CustomImage
                    src="/icons/twitter-icon.svg"
                    alt="Twitter"
                    width="40px"
                    height="40px"
                    className="footerLinks"
                  />
                </Link>

                <Link
                  to={"https://app.slack.com/client/T87MFUR42/D03H49ZSVDE"}
                  target="_blank"
                  rel="noopener noreferrer nofollow"
                  title="Contact us via Slack"
                  aria-label="Contact us via Slack"
                >
                  <SiSlack color="#fff" className="footerLinks" alt="Slack" />
                </Link>
              </div>
            </div>
          </Col>
          <Col span={24}>
            <p>© 2024 Tansy Academy. All rights reserved</p>
          </Col>
        </Row>
      </Container>
    </CustomContainer>
  );
};

export default Footer;
