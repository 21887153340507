import React, { useEffect } from "react";
import { Container } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { getScreenPermissions } from "../../redux/userSlice";
import { Wrapper } from "../quiz/index.style";
import { TableContainer } from "../Forum/manageTopic/index.style";
import { Button, Tag } from "antd";
import moment from "moment";
import { enrollCourse } from "../../redux/courseSlice";
import { Link, useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";

const Permissions = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { screenPermissions, loading } = useSelector((state) => state?.user);
  const { enrollLoading } = useSelector((state) => state?.course);

  useEffect(() => {
    dispatch(getScreenPermissions());
  }, [dispatch, enrollLoading]);

  const accessControl = {
    videoAccess: "Video Access",
    liveClassAccess: "Live Class Access",
    lessonCommentAccess: "Lesson Comment Access",
    lessonAttachmentAccess: "Lesson Attachments Access",
    quizAccess: "Quiz Access",
    queryToolAccess: "Query Tool Access",
    assignmentAccess: "Assignment Access",
  };

  const dataSource = (screenPermissions || [])?.map((data, i) => {
    const expiryChecks = moment(
      moment(data?.expiryDate).format("MMM DD YYYY")
    ).isSameOrAfter(moment().format("MMM DD YYYY"));

    return {
      key: i,
      accessControl: accessControl[data?.access] || null,
      courseName: data?.courseName || "-",
      packageName: data?.packageName?.toUpperCase(),
      expiryDate: (
        <div
          style={{
            color: expiryChecks ? "#00AC47" : "#C91D2E",
          }}
        >
          {moment(data?.expiryDate).format("MMM DD YYYY")}
        </div>
      ),
      courseEnrolled: (
        <div
          style={
            {
              // color:
              //   data?.enrolled === true
              //     ? "#00AC47"
              //     : data?.enrolled === null
              //     ? "#00AC47"
              //     : "#C91D2E",
            }
          }
        >
          {data?.enrolled === true ? (
            <Tag color="blue">True </Tag>
          ) : data?.enrolled === null ? (
            ""
          ) : (
            <Tag color="red">False </Tag>
          )}
        </div>
      ),
      enrollNow: (
        <div>
          {data?.enrolled === true ? (
            <Button
              onClick={() => {
                navigate("/user/dashboard");
              }}
              style={{ width: "100px", color: "#3F6FD8" }}
            >
              Enrolled
            </Button>
          ) : data?.enrolled === null ? (
            ""
          ) : (
            <Button
              style={{ width: "100px", color: "#D39331" }}
              disabled={!expiryChecks}
              onClick={() => {
                dispatch(
                  enrollCourse({ courseID: { courseId: data?.courseId } })
                );
              }}
            >
              Enroll Now
            </Button>
          )}
        </div>
      ),
    };
  });

  const columns = [
    {
      title: "Access Control",
      dataIndex: "accessControl",
      key: "accessControl",
    },
    {
      title: "Course Name",
      dataIndex: "courseName",
      key: "courseName",
    },
    {
      title: "Expiry Date",
      dataIndex: "expiryDate",
      key: "expiryDate",
    },
    {
      title: "Course Enrolled",
      dataIndex: "courseEnrolled",
      key: "courseEnrolled",
    },
    {
      title: "Enroll Now",
      dataIndex: "enrollNow",
      key: "enrollNow",
    },
  ];
  return (
    <Wrapper>
      <Helmet>
        <title>Tansy Academy | Sceeen Permissions</title>
      </Helmet>
      <Container>
        <div className="mt-4 mb-4">
          <h3>Access Control</h3>
          <p className="pt-2 pb-2">
            Total access levels: {screenPermissions.length}
          </p>
        </div>

        <TableContainer
          loading={loading || enrollLoading}
          dataSource={dataSource}
          columns={columns}
          pagination={{
            pageSize: screenPermissions.length,
            total: screenPermissions.length || 0,
          }}
          locale={{
            emptyText: (
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "50vh",
                  textAlign: "center",
                }}
              >
                <p>
                  Please complete the payment to access the enrollment screen.{" "}
                  <Link
                    to="/learn_sql_power_bi_analytics_online_training_cost"
                    style={{ textDecoration: "underline" }}
                  >
                    Click here
                  </Link>{" "}
                  to make the payment.
                </p>
              </div>
            ),
          }}
        />
      </Container>
    </Wrapper>
  );
};

export default Permissions;
