import { Link } from "react-router-dom";

export const sectionsData = [
  {
    id: 1,
    title: "Key takeaways from our SQL training courses:",
    image: "/images/Key-takeaways-from-our -SQL-training-courses.svg",
    items: [
      "Increased awareness of various software applications.",
      <>
        Fundamental understanding of data and{" "}
        <Link to="https://tansyacademy.com/mysql/software-applications-introduction/sql-training-database-components">
          databases
        </Link>
        .
      </>,
      "Expert-level SQL skills.",
      <>
        Completion of an{" "}
        <Link to="https://tansyacademy.com/mysql/sql-database-training-projects/sql-database-final-capstone-project-student-management-system">
          SQL database capstone project.
        </Link>
        .
      </>,
      "Get job-ready through job search strategies, resume building, and interview preparation.",
      <>
        During our SQL course you will be having the opportunity to concentrate
        on any one of the databases like{" "}
        <Link to="https://tansyacademy.com/course_detail?name=MySQL">
          MySQL
        </Link>
        ,{" "}
        <Link to="https://tansyacademy.com/course_detail?name=PostgreSQL">
          PostgreSQL
        </Link>
        ,{" "}
        <Link to="https://tansyacademy.com/course_detail?name=Microsoft%20SQL%20Server">
          MS SQL Server
        </Link>{" "}
        and{" "}
        <Link to="https://tansyacademy.com/course_detail?name=Oracle">
          Oracle
        </Link>
      </>,
    ],
  },
  {
    id: 2,
    title: "Who this SQL course is for?",
    image: "/images/Learn-SQL.svg",
    items: [
      "Current bachelor's students in fields such as Arts, Accounts, Computers, Science, Maths, and other disciplines.",
      "Graduates (bachelor’s degree holders) who are looking to start a career in the IT industry.",
      "Students with backgrounds in BA, BSc, BEd, BTech, BE, MSc, MTech, or MCA are also welcome.",
      "Teachers and lecturers aiming to transition into the IT field with little or no prior IT experience.",
      "Accountants interested in shifting to the IT sector.",
      "Sales and marketing professionals looking to move into IT roles.",
      "Insurance professionals seeking a career change into the IT field.",
      "Data entry professionals aiming to switch to programming roles.",
      "QA testers looking to advance their careers and expand their skill set.",
    ],
  },
];

export const dataLevel = [
  {
    title: "Level 1",
    heading: "Live SQL Classes",
    content: (
      <>
        We will provide morning and evening batches to accommodate students
        based on their busy schedules. During their{" "}
        <Link to="https://tansyacademy.com/learn_sql_power_bi_analytics_online_training_cost">
          subscription
        </Link>{" "}
        period, students can attend multiple{" "}
        <Link to="https://tansyacademy.com/learn_sql_class_online_sql_training_course">
          SQL batches/classes
        </Link>
        .
      </>
    ),
  },
  {
    title: "Level 2",
    heading: "Online SQL Content",
    content: (
      <>
        All{" "}
        <Link to="https://tansyacademy.com/mysql/software-applications-introduction/sql-training-application-software">
          sql course content
        </Link>{" "}
        will be hosted online, including over 1000+ sample SQL queries available
        throughout the duration of the course. Lifetime access to the static
        course content will be provided free of charge.
      </>
    ),
  },
  {
    title: "Level 3",
    heading: "Online SQL Videos",
    content: (
      <>
        During the valid subscription period, students will have access to
        premium{" "}
        <Link to="https://tansyacademy.com/mysql/data-query-language-sql-dql/sql-group-by">
          sql video content
        </Link>{" "}
        for each lesson. Class videos will be uploaded to their respective
        lessons online.
      </>
    ),
  },
  {
    title: "Level 4",
    heading: "Online SQL Query Editor (live sql tool)",
    content: (
      <>
        We offer a comprehensive and robust online{" "}
        <Link to="https://tansyacademy.com/online_sql_query_editor_execution">
          SQL query tool
        </Link>{" "}
        that provides a user-friendly interface and a wide range of features to
        enhance your database querying experience. With our tool, you can write
        and execute SQL queries efficiently, visualize query results, and
        analyze data seamlessly, making it an indispensable resource for
        database professionals and learners alike.You can bypass the painstaking
        process of installing MySQL Server, Postgres database, Oracle, or MSSQL
        Server on your computers by utilizing our online SQL query tool to
        execute queries against these databases seamlessly.
      </>
    ),
  },
  {
    title: "Level 5",
    heading: "Online SQL Quiz",
    content: (
      <>
        Throughout our SQL training course, you'll encounter over 11,000+ sql
        dabatase quiz questions to test your knowledge and gauge your progress.
        This approach allows you to measure your skills and build confidence in
        securing a new job as an SQL developer. Our quiz questions will be
        common across postgresql dbms (pgsql), msft sql server, mysql db and
        other db ms sql{" "}
        <Link to="https://tansyacademy.com/mysql/sql-certifications/sql-training-mysql-certifications">
          certification course
        </Link>{" "}
        like oracle.
      </>
    ),
  },
  {
    title: "Level 6",
    heading: "Database Projects and Assignments",
    content: (
      <>
        Our training focuses on practical, hands-on experience, offering you the
        opportunity to work on 8 real-world{" "}
        <Link to="https://tansyacademy.com/sql_database_projects">
          sql database projects
        </Link>
        , comprising 68 smaller assignments. Additionally, our online resources
        include over 2,500 sample SQL queries, and your assignments will cover
        more than 1,500 SQL queries that you will write and master. To further
        support your learning, each student is assigned a technical mentor who
        will review and guide you through your project assignments.
      </>
    ),
  },
  {
    title: "Level 7",
    heading: "Live webapp coding",
    content: (
      <>
        During the course, you will have exposure to real-world{" "}
        <Link to="https://tansycloud.com">LIVE web applications</Link> and will
        be provided with REAL TIME SQL code examples for most of the SQL topics
        covered in the curriculum.
      </>
    ),
  },
  {
    title: "Level 8",
    heading: "SQL Capstone Project",
    content: (
      <>
        You will participate in two{" "}
        <Link to="https://tansyacademy.com/mysql/sql-database-training-projects/sql-database-final-capstone-project-student-management-system">
          real-world sql projects:
        </Link>{" "}
        one guided during Zoom classes and a final project to demonstrate your
        SQL skills independently.
      </>
    ),
  },
  {
    title: "Level 9",
    heading: "Personal Coach/Mentor",
    content: (
      <>
        You will be paired with a dedicated personal mentor who will provide
        additional guidance to help you complete your{" "}
        <Link to="https://tansyacademy.com/sql_database_projects">
          sql assignments
        </Link>
        .
      </>
    ),
  },
  {
    title: "Level 10",
    heading: "Mock Interview",
    content: (
      <>
        We will conduct several mock interviews to boost your confidence and
        prepare you for real-world interviews, enhancing your ability to excel
        and succeed. Additionally, our collection of over 3,250 online{" "}
        <Link to="https://tansyacademy.com/mssql/database_interview_questions">
          sql database interview questions
        </Link>{" "}
        will further strengthen your confidence for actual job interviews.
      </>
    ),
  },
  {
    title: "Level 11",
    heading: "Online SQL Forum",
    content: (
      <>
        An online{" "}
        <Link to="https://tansyacademy.com/forum-threads">SQL forum</Link>{" "}
        provides a collaborative platform where users can ask questions, share
        knowledge, and engage with a community of SQL enthusiasts and
        professionals. It's a valuable resource for troubleshooting SQL-related
        issues, learning new techniques, and staying updated on industry trends.
      </>
    ),
  },
  {
    title: "Level 12",
    heading: "WhatsApp and Slack chats",
    content: (
      <>
        We offer support to our SQL course students through dedicated{" "}
        <Link to="https://wa.me/+971504614868">WhatsApp</Link> and Slack chats,
        providing real-time assistance, clarifications, and additional guidance
        beyond the classroom. These communication channels facilitate
        interactive discussions and foster a supportive learning community among
        students and instructors.
      </>
    ),
  },
  {
    title: "Level 13",
    heading: "Power BI",
    content: (
      <>
        After completing the SQL query{" "}
        <Link to="https://tansyacademy.com/courses/sql_database_programming_tutorial_courses">
          training courses
        </Link>{" "}
        , students will have the opportunity to pursue Power BI, enabling them
        to target various job roles such as BI analyst, Power BI developer, and
        Reports developer.
      </>
    ),
  },
  {
    title: "Level 14",
    heading: "Internship",
    content: (
      <>
        Students with premium subscriptions will undergo additional screening to
        qualify for{" "}
        <Link to="https://tansyacademy.com/sql_power_bi_software_it_job_internship">
          internship
        </Link>{" "}
        opportunities at Tansy Cloud, a software development firm.
      </>
    ),
  },
  {
    title: "Level 15",
    heading: "One Family",
    content: (
      <>
        We consider our students as part of our extended family, sharing a
        common <Link to="https://tansyacademy.com/about">goal</Link> of securing
        new jobs in the IT field. Throughout this journey, you will have our
        full support, and we encourage you to stay connected even after securing
        a job to support our community
      </>
    ),
  },
];

export const syllabusData = [
  {
    header: "Chapter 1",
    main: "Course Introduction",
    key: "1",
    lesson: "Lessons",
    lessontext: [
      <Link to="https://tansyacademy.com/mysql/sql-database-learning-training-intro/live-sql-online-training-program-database-course-for-beginners-and-advanced-expert-level">
        Introduction
      </Link>,
    ],
    assign: "Assignments",
    assigntext: ["-"],
  },
  {
    header: "Chapter 2",
    main: "Introduction to Software Applications",
    key: "2",
    lesson: "Lessons",
    lessontext: [
      <Link to="https://tansyacademy.com/mysql/software-applications-introduction/sql-training-application-software">
        Application Software
      </Link>,
      <Link to="https://tansyacademy.com/mysql/software-applications-introduction/sql-training-software-development-life-cycle">
        SDLC
      </Link>,
      <Link to="https://tansyacademy.com/mysql/software-applications-introduction/sql-training-database-components">
        Database Components
      </Link>,
      <Link to="https://tansyacademy.com/mysql/software-applications-introduction/sql-training-database-platforms">
        Database Platforms
      </Link>,
      <Link to="https://tansyacademy.com/mysql/software-applications-introduction/sql-training-gui-tools-sql-development">
        GUI tools
      </Link>,
      <Link to="https://tansyacademy.com/mysql/software-applications-introduction/sql-training-tansy-cloud-business-application">
        Tansy Cloud
      </Link>,
    ],
    assign: "Assignments",
    assigntext: [
      <Link to="https://tansyacademy.com/mysql/software-applications-introduction/sql-database-project-assignment-zoom-business-application">
        Analyse Zoom App
      </Link>,
      <Link to="https://tansyacademy.com/mysql/software-applications-introduction/sql-database-project-assignment-facebook-social-application">
        Analyse Facebook
      </Link>,
      <Link to="https://tansyacademy.com/mysql/software-applications-introduction/sql-database-project-assignment-whatsapp-mobile-application">
        Analyse WhatsApp
      </Link>,
      <Link to="https://tansyacademy.com/postgresql/software-applications-introduction/sql-database-project-assignment-salesforce-business-application">
        Analyzeg Salesforce
      </Link>,
      <Link to="https://tansyacademy.com/postgresql/software-applications-introduction/sql-database-project-assignment-instagram-social-application">
        Analyze Instagram
      </Link>,
      <Link to="https://tansyacademy.com/postgresql/software-applications-introduction/sql-database-project-assignment-snapchat-mobile-application">
        Analyze Snapchat
      </Link>,
      <Link to="https://tansyacademy.com/oracle/software-applications-introduction/sql-database-project-assignment-slack-business-application">
        Assignment: Analyzing Slack Business Application
      </Link>,
      <Link to="https://tansyacademy.com/oracle/software-applications-introduction/sql-database-project-assignment-twitter-social-application">
        Assignment: Analyzing Twitter Social Application
      </Link>,
      <Link to="https://tansyacademy.com/oracle/software-applications-introduction/sql-database-project-assignment-uber-mobile-application">
        Assignment: Analyzing Uber Mobile Application
      </Link>,
      <Link to="https://tansyacademy.com/mssql/software-applications-introduction/sql-database-project-assignment-linkedIn-business-application">
        Assignment: Analyzing LinkedIn Business Application
      </Link>,
      <Link to="https://tansyacademy.com/mssql/software-applications-introduction/sql-database-project-assignment-netflix-social-application">
        Analyze Netflix
      </Link>,
      <Link to="https://tansyacademy.com/mssql/software-applications-introduction/sql-database-project-assignment-gmail-mobile-application">
        Analyze Gmail App
      </Link>,
    ],
  },
  {
    header: "Chapter 3",
    main: "Database Tables",
    key: "3",
    lesson: "Lessons",
    lessontext: [
      <Link to="https://tansyacademy.com/mysql/database-tables-columns-rows/sql-database-table">
        Database
      </Link>,
      <Link to="https://tansyacademy.com/mysql/database-tables-columns-rows/database-table-columns-rows">
        Database Tables
      </Link>,
      <Link to="https://tansyacademy.com/mysql/database-tables-columns-rows/mysql-structured-query-language-db-database-sql">
        What is SQL?
      </Link>,
    ],
    assign: "Assignments",
    assigntext: [
      <Link to="https://tansyacademy.com/mysql/database-tables-columns-rows/sql-database-project-assignment-list-tables-columns-company-management-system">
        List Tables - Company App
      </Link>,
      <Link to="https://tansyacademy.com/mysql/database-tables-columns-rows/sql-database-project-assignment-list-tables-columns-student-management-system">
        List Tables - Student App
      </Link>,
      <Link to="https://tansyacademy.com/postgresql/database-tables-columns-rows/sql-database-project-assignment-list-tables-columns-banking-management-system">
        List Tables - Banking App
      </Link>,
      <Link to="https://tansyacademy.com/postgresql/database-tables-columns-rows/sql-database-project-assignment-list-tables-columns-hospital-management-system">
        List Tables - Hospital App
      </Link>,
      <Link to="https://tansyacademy.com/oracle/database-tables-columns-rows/sql-database-project-assignment-list-tables-columns-library-management-system">
        List Tables - Library App
      </Link>,
      <Link to="https://tansyacademy.com/oracle/database-tables-columns-rows/sql-database-project-assignment-list-tables-columns-facebook-like-social-media-application">
        List Tables - Facebook App
      </Link>,
      <Link to="https://tansyacademy.com/mssql/database-tables-columns-rows/sql-database-project-assignment-list-tables-columns-store-management-system">
        List Tables - Store App
      </Link>,
      <Link to="https://tansyacademy.com/mssql/database-tables-columns-rows/sql-database-project-assignment-list-tables-columns-food-delivery-application">
        List Tables - Delivery App
      </Link>,
    ],
  },
  {
    header: "Chapter 4",
    main: "Setup Sample Database",
    key: "4",
    lesson: "Lessons",
    lessontext: [
      <Link to="https://tansyacademy.com/mysql/setup-sample-database/online-sql-editor-mysql-query-tool">
        SQL Editor
      </Link>,
      <Link to="https://tansyacademy.com/mysql/setup-sample-database/sql-database-cloud-hosting-tips-for-beginners">
        Database Hosting
      </Link>,
    ],
    assign: "Assignments",
    assigntext: ["-"],
  },
  {
    header: "Chapter 5",
    main: "Data Definition Language",
    key: "5",
    lesson: "Lessons",
    lessontext: [
      <Link to="https://tansyacademy.com/mysql/data-definition-language-sql-ddl/sql-create-table">
        CREATE TABLE
      </Link>,
      <Link to="https://tansyacademy.com/mysql/data-definition-language-sql-ddl/sql-alter-table">
        ALTER TABLE
      </Link>,
      <Link to="https://tansyacademy.com/mysql/data-definition-language-sql-ddl/sql-drop-table">
        DROP TABLE
      </Link>,
      <Link to="https://tansyacademy.com/mysql/data-definition-language-sql-ddl/ddl-data-types">
        Data Types
      </Link>,
      <Link to="https://tansyacademy.com/mysql/data-definition-language-sql-ddl/sql-json-data-type">
        JSON Data Type
      </Link>,
      <Link to="https://tansyacademy.com/mysql/data-definition-language-sql-ddl/sql-null-vs-not-null">
        NULL vs NOT NULL
      </Link>,
      <Link to="https://tansyacademy.com/mysql/data-definition-language-sql-ddl/sql-check-constraint">
        CHECK constraint
      </Link>,
      <Link to="https://tansyacademy.com/mysql/data-definition-language-sql-ddl/sql-default-constraint">
        DEFAULT constraint
      </Link>,
      <Link to="https://tansyacademy.com/mysql/data-definition-language-sql-ddl/sql-unique-constraint">
        UNIQUE constraint
      </Link>,
      <Link to="https://tansyacademy.com/mysql/data-definition-language-sql-ddl/sql-auto-increment">
        Auto Increment
      </Link>,
    ],
    assign: "Assignments",
    assigntext: [
      <Link to="https://tansyacademy.com/mysql/data-definition-language-sql-ddl/sql-ddl-assignment-company-management-system-project">
        DDL - Company App
      </Link>,
      <Link to="https://tansyacademy.com/mysql/data-definition-language-sql-ddl/sql-ddl-assignment-student-management-system-project">
        DDL - Student App
      </Link>,
      <Link to="https://tansyacademy.com/postgresql/data-definition-language-sql-ddl/sql-ddl-assignment-banking-application-system-project">
        DDL - Banking App
      </Link>,
      <Link to="https://tansyacademy.com/postgresql/data-definition-language-sql-ddl/sql-ddl-assignment-hospital-management-system-project">
        DDL - Hospital App
      </Link>,
      <Link to="https://tansyacademy.com/oracle/data-definition-language-sql-ddl/sql-ddl-assignment-library-management-system-project">
        DDL - Library App
      </Link>,
      <Link to="https://tansyacademy.com/oracle/data-definition-language-sql-ddl/sql-ddl-assignment-facebook-application-project">
        DDL - Facebook App
      </Link>,
      <Link to="https://tansyacademy.com/mssql/data-definition-language-sql-ddl/sql-ddl-assignment-store-management-system-project">
        DDL - Store App
      </Link>,
      <Link to="https://tansyacademy.com/mssql/data-definition-language-sql-ddl/sql-ddl-assignment-food-delivery-system-project">
        DDL - Delivery App
      </Link>,
    ],
  },
  {
    header: "Chapter 6",
    main: "Data Manipulation Language",
    key: "6",
    lesson: "Lessons",
    lessontext: [
      <Link to="https://tansyacademy.com/mysql/data-manipulation-language-sql-dml/sql-insert">
        INSERT
      </Link>,
      <Link to="https://tansyacademy.com/mysql/data-manipulation-language-sql-dml/sql-select-into">
        SELECT INTO
      </Link>,
      <Link to="https://tansyacademy.com/mysql/data-manipulation-language-sql-dml/sql-update">
        UPDATE
      </Link>,
      <Link to="https://tansyacademy.com/mysql/data-manipulation-language-sql-dml/sql-upsert">
        UPSERT
      </Link>,
      <Link to="https://tansyacademy.com/mysql/data-manipulation-language-sql-dml/sql-delete">
        DELETE
      </Link>,
      <Link to="https://tansyacademy.com/mysql/data-manipulation-language-sql-dml/sql-truncate">
        Truncate
      </Link>,
    ],
    assign: "Assignments",
    assigntext: [
      <Link to="https://tansyacademy.com/mysql/data-manipulation-language-sql-dml/dml-assignment-company-management-system-sql-database-project">
        DML - Company App
      </Link>,
      <Link to="https://tansyacademy.com/mysql/data-manipulation-language-sql-dml/dml-assignment-student-management-system-sql-database-project">
        DML - Student App
      </Link>,
      <Link to="https://tansyacademy.com/postgresql/data-manipulation-language-sql-dml/dml-assignment-banking-application-system-sql-database-project">
        DML - Banking App
      </Link>,
      <Link to="https://tansyacademy.com/postgresql/data-manipulation-language-sql-dml/dml-assignment-hospital-management-system-sql-database-project">
        DML - Hospital App
      </Link>,
      <Link to="https://tansyacademy.com/oracle/data-manipulation-language-sql-dml/dml-assignment-library-management-system-sql-database-project">
        DML - Library App
      </Link>,
      <Link to="https://tansyacademy.com/oracle/data-manipulation-language-sql-dml/dml-assignment-facebook-application-system-sql-database-project">
        DML - Facebook App
      </Link>,
      <Link to="https://tansyacademy.com/mssql/data-manipulation-language-sql-dml/dml-assignment-store-management-system-sql-database-project">
        DML - Store App
      </Link>,
      <Link to="https://tansyacademy.com/mssql/data-manipulation-language-sql-dml/dml-assignment-food-delivery-system-sql-database-project">
        DML - Delivery App
      </Link>,
    ],
  },
  {
    header: "Chapter 7",
    main: "Data Query Language",
    key: "7",
    lesson: "Lessons",
    lessontext: [
      <Link to="https://tansyacademy.com/mysql/data-query-language-sql-dql/sql-select-all">
        SELECT
      </Link>,
      <Link to="https://tansyacademy.com/mysql/data-query-language-sql-dql/sql-where-clause">
        WHERE Clause
      </Link>,
      <Link to="https://tansyacademy.com/mysql/data-query-language-sql-dql/sql-limit">
        LIMIT
      </Link>,
      <Link to="https://tansyacademy.com/mysql/data-query-language-sql-dql/sql-distinct">
        DISTINCT
      </Link>,
      <Link to="https://tansyacademy.com/mysql/data-query-language-sql-dql/sql-order-by">
        ORDER BY
      </Link>,
      <Link to="https://tansyacademy.com/mysql/data-query-language-sql-dql/sql-between-operator">
        BETWEEN
      </Link>,
      <Link to="https://tansyacademy.com/mysql/data-query-language-sql-dql/sql-concat">
        CONCAT
      </Link>,
      <Link to="https://tansyacademy.com/mysql/data-query-language-sql-dql/sql-in-operator">
        IN
      </Link>,
      <Link to="https://tansyacademy.com/mysql/data-query-language-sql-dql/sql-not-in-operator">
        NOT IN
      </Link>,
      <Link to="https://tansyacademy.com/mysql/data-query-language-sql-dql/sql-is-null-operator">
        IS NULL
      </Link>,
      <Link to="https://tansyacademy.com/mysql/data-query-language-sql-dql/sql-is-not-null-operator">
        IS NOT NULL
      </Link>,
      <Link to="https://tansyacademy.com/mysql/data-query-language-sql-dql/sql-and-or-operators">
        AND OR
      </Link>,
      <Link to="https://tansyacademy.com/mysql/data-query-language-sql-dql/sql-greater-than-less-than-operator">
        Greater Less Than
      </Link>,
      <Link to="https://tansyacademy.com/mysql/data-query-language-sql-dql/sql-not-equal-operator">
        Not Equal
      </Link>,
      <Link to="https://tansyacademy.com/mysql/data-query-language-sql-dql/sql-equal-operator">
        = (equal)
      </Link>,
      <Link to="https://tansyacademy.com/mysql/data-query-language-sql-dql/match-filter-data-like">
        LIKE
      </Link>,
      <Link to="https://tansyacademy.com/mysql/data-query-language-sql-dql/sql-not-like-operator">
        NOT LIKE
      </Link>,
      <Link to="https://tansyacademy.com/mysql/data-query-language-sql-dql/sql-exists-any-all-clause">
        EXISTS
      </Link>,
      <Link to="https://tansyacademy.com/mysql/data-query-language-sql-dql/sql-aliases">
        SQL Aliases
      </Link>,
      <Link to="https://tansyacademy.com/mysql/data-query-language-sql-dql/sql-group-by">
        GROUP BY
      </Link>,
      <Link to="https://tansyacademy.com/mysql/data-query-language-sql-dql/sql-having-clause">
        HAVING Clause
      </Link>,
      <Link to="https://tansyacademy.com/mysql/data-query-language-sql-dql/sql-count-sum-avg-min-max-functions">
        Aggregate Functions
      </Link>,
      <Link to="https://tansyacademy.com/mysql/data-query-language-sql-dql/sql-case-statement">
        CASE statement
      </Link>,
      <Link to="https://tansyacademy.com/mysql/data-query-language-sql-dql/sql-inner-join">
        INNER JOIN
      </Link>,
      <Link to="https://tansyacademy.com/mysql/data-query-language-sql-dql/sql-left-join">
        LEFT JOIN
      </Link>,
      <Link to="https://tansyacademy.com/mysql/data-query-language-sql-dql/sql-right-join">
        RIGHT JOIN
      </Link>,
      <Link to="https://tansyacademy.com/mysql/data-query-language-sql-dql/sql-union-union-all">
        UNION
      </Link>,
      <Link to="https://tansyacademy.com/mysql/data-query-language-sql-dql/sql-pivot">
        SQL PIVOT
      </Link>,
      <Link to="https://tansyacademy.com/mysql/data-query-language-sql-dql/sql-sub-queries">
        SQL subquery
      </Link>,
      <Link to="https://tansyacademy.com/mysql/data-query-language-sql-dql/sql-group-rank">
        Group Rank
      </Link>,
      <Link to="https://tansyacademy.com/mysql/data-query-language-sql-dql/sql-json-select">
        JSON data
      </Link>,
      <Link to="https://tansyacademy.com/mysql/data-query-language-sql-dql/sql-arithmetic-operators">
        Arithmetic Operators
      </Link>,
      <Link to="https://tansyacademy.com/mysql/data-query-language-sql-dql/sql-comparison-operators">
        Comparison Operators
      </Link>,
      <Link to="https://tansyacademy.com/mysql/data-query-language-sql-dql/sql-logical-operators">
        Logical Operators
      </Link>,
      <Link to="https://tansyacademy.com/mysql/data-query-language-sql-dql/sql-string-functions">
        STRING functions
      </Link>,
      <Link to="https://tansyacademy.com/mysql/data-query-language-sql-dql/sql-date-functions">
        DATE functions
      </Link>,
      <Link to="https://tansyacademy.com/mysql/data-query-language-sql-dql/sql-numeric-functions">
        NUMERIC functions
      </Link>,
      <Link to="https://tansyacademy.com/mysql/data-query-language-sql-dql/sql-execution-order">
        SQL Execution Order
      </Link>,
      <Link to="https://tansyacademy.com/mysql/data-query-language-sql-dql/sql-cast">
        CAST
      </Link>,
      <Link to="https://tansyacademy.com/mysql/data-query-language-sql-dql/sql-coalesce">
        COALESCE
      </Link>,
    ],
    assign: "Assignments",
    assigntext: [
      <Link to="https://tansyacademy.com/mysql/data-query-language-sql-dql/dql-assignment-company-management-system-sql-database-project">
        DQL - Company App
      </Link>,
      <Link to="https://tansyacademy.com/mysql/data-query-language-sql-dql/dql-assignment-logic-dashboard-reporting-company-management-system-sql-database-project">
        Dashboard - Company App
      </Link>,
      <Link to="https://tansyacademy.com/mysql/data-query-language-sql-dql/dql-assignment-student-management-system-sql-database-project">
        DQL - Student App
      </Link>,
      <Link to="https://tansyacademy.com/mysql/data-query-language-sql-dql/dql-assignment-logic-dashboard-reporting-student-management-system-sql-database-project">
        Dashboard - Student App
      </Link>,
      <Link to="https://tansyacademy.com/postgresql/data-query-language-sql-dql/dql-assignment-banking-application-system-sql-database-project">
        DQL - Banking App
      </Link>,
      <Link to="https://tansyacademy.com/postgresql/data-query-language-sql-dql/dql-assignment-logic-dashboard-reporting-banking-application-sql-database-project">
        Dashboard - Banking App
      </Link>,
      <Link to="https://tansyacademy.com/postgresql/data-query-language-sql-dql/dql-assignment-hospital-management-system-sql-database-project">
        DQL - Hospital App
      </Link>,
      <Link to="https://tansyacademy.com/postgresql/data-query-language-sql-dql/dql-assignment-logic-dashboard-reporting-hospital-management-system-sql-database-project">
        Dashboard - Hospital App
      </Link>,
      <Link to="https://tansyacademy.com/oracle/data-query-language-sql-dql/dql-assignment-library-management-system-sql-database-project">
        DQL - Library App
      </Link>,
      <Link to="https://tansyacademy.com/oracle/data-query-language-sql-dql/dql-assignment-logic-dashboard-reporting-library-management-system-sql-database-project">
        Dashboard - Library App
      </Link>,
      <Link to="https://tansyacademy.com/oracle/data-query-language-sql-dql/dql-assignment-facebook-application-system-sql-database-project">
        DQL - Facebook App
      </Link>,
      <Link to="https://tansyacademy.com/oracle/data-query-language-sql-dql/dql-assignment-logic-dashboard-reporting-facebook-application-sql-database-project">
        Dashboard - Facebook App
      </Link>,
      <Link to="https://tansyacademy.com/mssql/data-query-language-sql-dql/dql-assignment-store-management-system-sql-database-project">
        DQL - Store App
      </Link>,
      <Link to="https://tansyacademy.com/mssql/data-query-language-sql-dql/dql-assignment-logic-dashboard-reporting-store-management-system-sql-database-project">
        Dashboard - Store App
      </Link>,
      <Link to="https://tansyacademy.com/mssql/data-query-language-sql-dql/dql-assignment-food-delivery-system-sql-database-project">
        DQL - Delivery App
      </Link>,
      <Link to="https://tansyacademy.com/mssql/data-query-language-sql-dql/dql-assignment-logic-dashboard-reporting-food-delivery-system-sql-database-project">
        Dashboard - Delivery App
      </Link>,
    ],
  },
  {
    header: "Chapter 8",
    main: "RDBMS Concepts",
    key: "8",
    lesson: "Lessons",
    lessontext: [
      <Link to="https://tansyacademy.com/mysql/database-sql-rdbms-concepts/sql-what-is-rdbms-relational-database-management-system">
        What is RDBMS
      </Link>,
      <Link to="https://tansyacademy.com/mysql/database-sql-rdbms-concepts/sql-primary-key">
        Primary key
      </Link>,
      <Link to="https://tansyacademy.com/mysql/database-sql-rdbms-concepts/sql-foreign-key">
        Foreign key
      </Link>,
      <Link to="https://tansyacademy.com/mysql/database-sql-rdbms-concepts/sql-cardinality">
        Cardinality
      </Link>,
      <Link to="https://tansyacademy.com/mysql/database-sql-rdbms-concepts/sql-normalization-first-normal-form">
        1NF
      </Link>,
      <Link to="https://tansyacademy.com/mysql/database-sql-rdbms-concepts/sql-second-normal-form">
        2NF
      </Link>,
      <Link to="https://tansyacademy.com/mysql/database-sql-rdbms-concepts/sql-third-normal-form">
        3NF
      </Link>,
      <Link to="https://tansyacademy.com/mysql/database-sql-rdbms-concepts/sql-keys-business-natural-candidate">
        Business key
      </Link>,
      <Link to="https://tansyacademy.com/mysql/database-sql-rdbms-concepts/sql-surrogate-key">
        Surrogate Key
      </Link>,
      <Link to="https://tansyacademy.com/mysql/database-sql-rdbms-concepts/sql-database-index">
        Database Index
      </Link>,
      <Link to="https://tansyacademy.com/mysql/database-sql-rdbms-concepts/sql-index-types">
        Index Types
      </Link>,
      <Link to="https://tansyacademy.com/mysql/database-sql-rdbms-concepts/sql-structured-vs-unstructured-data">
        Structured VS Unstructured
      </Link>,
      <Link to="https://tansyacademy.com/mysql/database-sql-rdbms-concepts/sql-erd-entity-relationship-data-model">
        ERD (Data Model)
      </Link>,
    ],
    assign: "Assignments",
    assigntext: [
      <Link to="https://tansyacademy.com/mysql/database-sql-rdbms-concepts/sql-erd-data-model-diagram-database-project-company-management-system">
        Data Model - Company App
      </Link>,
      <Link to="https://tansyacademy.com/mysql/database-sql-rdbms-concepts/sql-erd-data-model-diagram-database-project-student-management">
        Data Model - Student App
      </Link>,
      <Link to="https://tansyacademy.com/postgresql/database-sql-rdbms-concepts/sql-erd-data-model-diagram-database-project-banking-application-system">
        Data Model - Banking App
      </Link>,
      <Link to="https://tansyacademy.com/postgresql/database-sql-rdbms-concepts/sql-erd-data-model-diagram-database-project-hospital-management-system">
        Data Model - Hospital App
      </Link>,
      <Link to="https://tansyacademy.com/oracle/database-sql-rdbms-concepts/sql-erd-data-model-diagram-database-project-library-management-system">
        Data Model - Library App
      </Link>,
      <Link to="https://tansyacademy.com/oracle/database-sql-rdbms-concepts/sql-erd-data-model-diagram-database-project-facebook-application">
        Data Model - Facebook App
      </Link>,
      <Link to="https://tansyacademy.com/mssql/database-sql-rdbms-concepts/sql-erd-data-model-diagram-database-project-store-management-system">
        Data Model - Store App
      </Link>,
      <Link to="https://tansyacademy.com/mssql/database-sql-rdbms-concepts/sql-erd-data-model-diagram-beginner-database-project-food-delivery">
        Data Model - Delivery App
      </Link>,
    ],
  },
  {
    header: "Chapter 9",
    main: "Advanced Topics",
    key: "9",
    lesson: "Lessons",
    lessontext: [
      <Link to="https://tansyacademy.com/mysql/sql-database-advanced-db-training-topics/sql-database-view">
        Database View
      </Link>,
      <Link to="https://tansyacademy.com/mysql/sql-database-advanced-db-training-topics/mysql-database-stored-procedure-db-sproc">
        Stored Procedure
      </Link>,
      <Link to="https://tansyacademy.com/mysql/sql-database-advanced-db-training-topics/sql-database-function">
        Functions
      </Link>,
      <Link to="https://tansyacademy.com/mysql/sql-database-advanced-db-training-topics/sql-database-triggers">
        Triggers
      </Link>,
      <Link to="https://tansyacademy.com/mysql/sql-database-advanced-db-training-topics/common-table-expession">
        CTE
      </Link>,
      <Link to="https://tansyacademy.com/mysql/sql-database-advanced-db-training-topics/sql-window-function">
        Window Functions
      </Link>,
    ],
    assign: "Assignments",
    assigntext: ["-"],
  },
  {
    header: "Chapter 10",
    main: "SQL DB Capstone Final Projects",
    key: "10",
    lesson: "Lessons",
    lessontext: [
      <Link to="https://tansyacademy.com/mysql/sql-database-training-projects/sql-database-final-capstone-project-company-management-system">
        Project: Company App
      </Link>,
      <Link to="https://tansyacademy.com/mysql/sql-database-training-projects/sql-database-final-capstone-project-student-management-system">
        Project: Student App
      </Link>,
      <Link to="https://tansyacademy.com/postgresql/sql-database-training-projects/sql-database-final-capstone-project-banking-system">
        Project: Banking App
      </Link>,
      <Link to="https://tansyacademy.com/postgresql/sql-database-training-projects/sql-database-final-capstone-project-hospital-management-system">
        Project: Hospital App
      </Link>,
      <Link to="https://tansyacademy.com/oracle/sql-database-training-projects/sql-database-final-capstone-project-library-management-system">
        Project: Library App
      </Link>,
      <Link to="https://tansyacademy.com/oracle/sql-database-training-projects/sql-database-final-capstone-project-facebook-application">
        Project: Facebook App
      </Link>,
      <Link to="https://tansyacademy.com/mssql/sql-database-training-projects/sql-database-final-capstone-project-store-management-system">
        Project: Store App
      </Link>,
      <Link to="https://tansyacademy.com/mssql/sql-database-training-projects/sql-database-final-capstone-project-food-delivery-system">
        Project: Delivery App
      </Link>,
    ],
    assign: "Assignments",
    assigntext: ["-"],
  },
];

export const jobRolesData = [
  {
    iconSrc: "/icons/sql-dev-icon.svg",
    title: "SQL Developer",
    description:
      "Develop and manage SQL databases by planning, developing, and maintaining the databases.",
  },
  {
    iconSrc: "/icons/data-analyst-icon.svg",
    title: "Database Developer",
    description:
      "Administering and troubleshooting computer databases and keep it secure.",
  },
  {
    iconSrc: "/icons/data-analyst-icon.svg",
    title: "Data Analyst",
    description:
      "Review data to identify key insights into a business's customers and ways the data can be used.",
  },
];

export const certificationsData = [
  {
    iconSrc: "/icons/microsoft-certifications.jpg",
    url: "https://learn.microsoft.com/en-us/credentials/certifications/azure-data-fundamentals",
    title: "Microsoft Certified: Azure Data Fundamentals",
    description:
      "This certification is an opportunity to demonstrate your knowledge of core data concepts and related Microsoft Azure data services.",
    color: " rgba(0, 100, 181, 1)",
    width: "60px",
    height: "55px",
  },
  {
    iconSrc: "/icons/oracle-certifications.jpg",
    url: "https://mylearn.oracle.com/ou/exam/oracle-database-sql-1z0-071/105037/110647/170369",
    title: "Oracle Database SQL Certified Associate (1Z0-071)",
    description:
      "The 1Z0-071 certification is an Oracle Database SQL certification that verifies an individual's understanding of SQL database concepts and skills.",
    color: " rgba(229, 17, 16, 1)",
    width: "60px",
    height: "55px",
  },
  {
    iconSrc: "/icons/mysql-certifications.jpg",
    url: "https://mylearn.oracle.com/ou/exam/mysql-80-database-developer-1z0-909/105037/110626/170352",
    title: "MySQL Database Developer Certified Professional",
    description:
      "This Certification demonstrates that you have a solid understanding of your job role and of MySQL products. Consultants, developers and DBAs use Oracle.",
    color: " rgba(72, 159, 179, 1)",
    width: "60px",
    height: "55px",
  },
  {
    iconSrc: "/icons/postgreSQL-certifications.png",
    url: "https://www.enterprisedb.com/course/postgresql-12-professional-certification",
    title: "Advanced PostgreSQL Professional Certification",
    description:
      "This certification validates a database professional's expertise in managing and maintaining Postgres servers and applications effectively in production environments.",
    color: "#013220",
    width: "60px",
    height: "55px",
  },
];

export const testimonialsData = [
  {
    id: 1,
    date: "March 5, 2023",
    message:
      "I found the MySQL course extremely beneficial. It provided all the necessary knowledge and skills I needed. Highly recommended!",
    occupation: "Student",
    name: "John Johnson",
    image:
      "https://lh3.googleusercontent.com/a/ACg8ocJti_KlbC3ph-rqHBOYmdoNDTY_1xLul9L3U3adnaCoEA=s96-c",
  },
  {
    id: 2,
    date: "April 10, 2023",
    message:
      "The MySQL course surpassed my expectations. It's a must for anyone aspiring to master database management.",
    occupation: "Student",
    name: "Emily Brown",
    image:
      "https://lh3.googleusercontent.com/a/ACg8ocJti_KlbC3ph-rqHBOYmdoNDTY_1xLul9L3U3adnaCoEA=s96-c",
  },
  {
    id: 3,
    date: "June 20, 2023",
    message:
      "I couldn't be happier with the MySQL course. It equipped me with valuable skills that are applicable in my field.",
    occupation: "Student",
    name: "Michael Davis",
    image:
      "https://lh3.googleusercontent.com/a/ACg8ocJti_KlbC3ph-rqHBOYmdoNDTY_1xLul9L3U3adnaCoEA=s96-c",
  },
  {
    id: 4,
    date: "July 3, 2023",
    message:
      "The MySQL course provided a seamless learning experience. I achieved exactly what I aimed for. Thank you!",
    occupation: "Student",
    name: "Sarah White",
    image:
      "https://lh3.googleusercontent.com/a/ACg8ocJti_KlbC3ph-rqHBOYmdoNDTY_1xLul9L3U3adnaCoEA=s96-c",
  },
  {
    id: 5,
    date: "August 15, 2023",
    message:
      "I was impressed by the quality of the MySQL course. It has motivated me to delve deeper into database management.",
    occupation: "Student",
    name: "David Lee",
    image:
      "https://lh3.googleusercontent.com/a/ACg8ocJti_KlbC3ph-rqHBOYmdoNDTY_1xLul9L3U3adnaCoEA=s96-c",
  },
  {
    id: 6,
    date: "September 25, 2023",
    message:
      "The MySQL course offers an intuitive learning platform. I highly recommend it to anyone interested in databases.",
    occupation: "Student",
    name: "Alice Clark",
    image:
      "https://lh3.googleusercontent.com/a/ACg8ocJti_KlbC3ph-rqHBOYmdoNDTY_1xLul9L3U3adnaCoEA=s96-c",
  },
];
